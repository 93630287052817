<template>
  <a-card :bordered="false" class="card-area">
    <common-table
      path="apiLog"
      :columns="columns"
    >
      <template slot="method" slot-scope="{record}">
        <a-tag v-if="record.method=='GET'" color="#87d068">{{record.method}}</a-tag>
        <a-tag v-else-if="record.method=='POST'" color="#2db7f5">{{record.method}}</a-tag>
        <a-tag v-else-if="record.method=='PUT'" color="#ffba5a">{{record.method}}</a-tag>
        <a-tag v-else-if="record.method=='DELETE'" color="#f50">{{record.method}}</a-tag>
      </template>

      <template slot="time" slot-scope="{record}">
        <a-tag v-if="record.spendTime<500" color="green">{{record.spendTime}} ms</a-tag>
        <a-tag v-else-if="record.spendTime<1000" color="cyan">{{record.spendTime}} ms</a-tag>
        <a-tag v-else-if="record.spendTime<1500" color="orange">{{record.spendTime}} ms</a-tag>
        <a-tag v-else color="red">{{record.spendTime}} ms</a-tag>
      </template>

      <template slot="status" slot-scope="{record}">
        <a-tag v-if="record.status<200" color="pink">{{record.status}}</a-tag>
        <a-tag v-else-if="record.status<201" color="green">{{record.status}}</a-tag>
        <a-tag v-else-if="record.status<399" color="cyan">{{record.status}}</a-tag>
        <a-tag v-else-if="record.status<403" color="orange">{{record.status}}</a-tag>
        <a-tag v-else color="red">{{record.status}}</a-tag>
      </template>

    </common-table>
  </a-card>
</template>

<script>
import moment from 'moment'
moment.locale('zh-cn')

export default {
  data () {
    return {
      advanced: false,
      dataSource: [],
      pagination: {
        defaultPageSize: 10,
        defaultCurrent: 1,
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false
    }
  },
  computed: {
    columns () {
      return [{
        title: '请求时间',
        dataIndex: 'startTime',
        customRender: (text, row, index) => {
          return moment(text).format('YYYY-MM-DD HH:mm:ss')
        }
      }, {
        title: '请求方法',
        dataIndex: 'method',
        scopedSlots: {customRender: 'method'},
        filters: [
          { text: 'GET', value: 'GET' },
          { text: 'POST', value: 'POST' },
          { text: 'PUT', value: 'PUT' },
          { text: 'DELETE', value: 'DELETE' }
        ],
        filterMultiple: false,
      }, {
        title: '请求URL',
        dataIndex: 'uri',
        customRender: (text, row, index) => {
          return text.split('?')[0]
        }
      }, {
        title: '请求描述',
        dataIndex: 'description',
      }, {
        title: '请求耗时',
        dataIndex: 'spendTime',
        scopedSlots: {customRender: 'time'},
      }]
    }
  },
  mounted () {
  },
  methods: {
    search () {
    },
    handleTableChange (pagination, filters, sorter) {
    },

  }
}
</script>

<style lang="less" scoped>
  @import "../../../static/less/Common";
  .alert {
    margin-bottom: .5rem;
  }
</style>
